<script setup lang="ts"></script>

<template>
  <BackgroundPatternWrapper>
    <NavBarSecondary />
    <slot />
    <FooterContainer />
  </BackgroundPatternWrapper>
</template>

<style scoped></style>
