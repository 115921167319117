<template>
  <nav
    class="flex gap-5 pt-2 text-sm text-white md:justify-center md:gap-16 md:text-base portrait:pl-4 portrait:pr-2 max-md:portrait:justify-between landscape:justify-center"
  >
    <LocaleLink
      v-for="item in infoItems"
      :key="item.href"
      :title="item.title"
      :href="item.href"
      class="bg-growing-underline-a capitalize"
      activeClass="bg-growing-underline-active font-bold"
    >
      {{ item.title }}
    </LocaleLink>
  </nav>
</template>

<script lang="ts" setup>
const { infoItems } = useLexmeaLinks();
</script>
