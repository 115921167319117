<template>
  <div
    class="h-full w-full max-w-[1000px] bg-transparent px-5 pb-3 pt-8 text-white md:px-10 lg:pb-10 lg:pt-12"
  >
    <FooterElement class="mb-6 md:mb-8">
      <img
        loading="lazy"
        class="h-[2rem] w-[8rem]"
        :src="logoIcon"
        alt="LexMea"
      />
    </FooterElement>
    <div
      class="mb-4 grid w-full grid-cols-2 md:grid-cols-3 md:pl-9 lg:mb-0 lg:w-[90%]"
    >
      <!--   Col 1 & 2   -->
      <div
        class="col-span-2 flex flex-col max-md:justify-between max-md:gap-4 md:flex-row md:gap-20"
      >
        <FooterElement class="max-md:mb-4">
          <div
            class="flex flex-row justify-center gap-5 md:flex-col md:justify-start md:gap-1"
          >
            <LibraryNavigator
              data-pw="library-link"
              class="bg-growing-underline-a flex w-fit items-center gap-1 align-middle"
            >
              <img
                :src="library"
                class="inline-block h-3 w-3 md:h-5 md:w-5"
                :alt="$t('library')"
                aria-hidden="true"
              />
              {{ $t("library") }}
            </LibraryNavigator>
            <ActiveParagraphLink
              data-pw="workspace-link"
              class="bg-growing-underline-a flex w-fit items-center gap-1 align-middle"
            >
              <WorkspaceIcon class="h-3 w-3 md:h-5 md:w-5" aria-hidden="true" />
              {{ $t("workspace") }}
            </ActiveParagraphLink>
          </div>
        </FooterElement>
        <FooterElement class="lg:pl-4">
          <h2
            class="!mb-0 whitespace-nowrap text-center text-white md:text-start"
          >
            {{ $t("info_site") }}
          </h2>
          <div
            class="flex justify-center gap-6 whitespace-nowrap max-md:mx-auto max-md:text-xs md:flex-col md:items-start md:justify-start md:gap-1 md:pl-4"
          >
            <LocaleLink
              v-for="item in infoItems"
              :key="item.href"
              class="bg-growing-underline-a w-fit"
              :href="item.href"
              @click="handleSelectSameRoute(item.href)"
            >
              {{ item.title }}
            </LocaleLink>
          </div>
        </FooterElement>
      </div>

      <!--   Col 3   -->
      <FooterElement
        class="col-span-2 items-center justify-center max-md:mt-8 md:col-span-1"
      >
        <h2 class="!mb-0 w-full text-center text-white md:text-start">
          {{ $t("misc") }}
        </h2>
        <div
          class="flex w-full max-w-96 justify-center gap-2 whitespace-nowrap max-md:text-xs md:flex-col md:items-start md:justify-start md:gap-1 md:pl-4"
        >
          <LocaleLink
            v-for="item in miscItems.slice(0, 3)"
            :key="item.href"
            class="bg-growing-underline-a"
            :href="item.href"
            @click="handleSelectSameRoute(item.href)"
          >
            {{ item.title }}
          </LocaleLink>
        </div>
        <div
          class="my-2 flex w-full max-w-96 justify-center gap-2 whitespace-nowrap max-md:text-xs md:flex-col md:items-start md:justify-start md:gap-1 md:pl-4"
        >
          <LocaleLink
            v-for="item in miscItems.slice(3)"
            :key="item.href"
            class="bg-growing-underline-a"
            :href="item.href"
            @click="handleSelectSameRoute(item.href)"
          >
            {{ item.title }}
          </LocaleLink>
          <button class="bg-growing-underline-a" @click="openFeedback">
            {{ $t("feedback") }}
          </button>
        </div>
      </FooterElement>
    </div>
  </div>
</template>

<script lang="ts" setup>
import logoIcon from "~/assets/logo.svg";
import library from "~/assets/header/library.svg";

const currentPath = useCurrentPath();
const { open: openFeedback } = useFeedbackModal();
const mainContainer = ref<HTMLElement | null>(null);
onMounted(() => {
  mainContainer.value = document.querySelector("main");
});

const { y } = useScroll(mainContainer, { behavior: "smooth" });

function handleSelectSameRoute(route: string) {
  if (route === currentPath.value) {
    y.value = 0;
  }
}

const { miscItems, infoItems } = useLexmeaLinks();
</script>

<style scoped>
h2 {
  @apply mb-2 text-lg font-bold md:text-xl;
}
</style>
